import React from 'react';
import { graphql } from 'gatsby';

import { MeetTheTeamPageTemplate } from '../templates';
import { Layout } from '../components';

const MeetTheTeamPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const members = data.allMarkdownRemark.edges.map(({ node }) => node.frontmatter);
    return (
        <Layout title={ frontmatter.headerTitle }>
            <MeetTheTeamPageTemplate { ...frontmatter } members={ members }/>
        </Layout>
    );
};

export default MeetTheTeamPage;

export const pageQuery = graphql`
    query MeetTheTeamPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "meet-the-team-page" } }) {
            frontmatter {
                headerImage {
                    cloudinaryMedia
                    assetType
                    deliveryType
                    urlRemainder
                    sourceURL
                }
                headerTitle
                heroTitle
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "team-member" } } }
            sort: { fields: [ frontmatter___sortOrder, frontmatter___role, frontmatter___name ] }
        ) {
            edges {
                node {
                    frontmatter {
                        name
                        blurb
                        picture {
                            assetType
                            cloudinaryMedia
                            deliveryType
                            sourceURL
                            urlRemainder
                        }
                        role
                        linkedin
                    }
                }
            }
        }
    }
`;